<template>
	<div class="forgot-password">
		<template>
			<form class="form">
				<h2 style="margin-bottom: 2rem">
					Fill in your email address to receive instructions on resetting your password.
				</h2>

				<div v-show="formError" class="form-error">
					<ul>
						<li key="error-message">
							<template>
								{{ errorMessage }}
							</template>
						</li>
					</ul>
				</div>

				<div class="form-group">
					<label class="required" for="email">Email</label>
					<input
						id="email"
						name="email"
						type="text"
						size="56"
						maxlength="100"
						autocomplete="false"
						value=""
						class="text"
						v-model="loginDetails.username"
					/>
				</div>
				<button
					type="button"
					class="form-btn"
					style="float: right; margin-top: 0.5rem"
					@click="forgotLogin()"
				>
					Enter
				</button>
			</form>
		</template>

		<Modal v-if="success" title="Request Completed" @close="success = false">
			<template v-slot:content>
				<span>
					The instructions to reset your password have been emailed to
					{{ loginDetails.username }}.
				</span>
			</template>
		</Modal>
	</div>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
	name: "ForgotPassword",
	components: {
		Modal,
	},
	data() {
		return {
			loginDetails: {
				username: "",
				password: "",
			},
			forgotPassword: false,
			formError: false,
			errorMessage: "",
			success: false,
		};
	},
	methods: {
		async forgotLogin() {
			try {
				let resp = await this.$http.post(`/api/account/request-password-reset`, {
					username: this.loginDetails.username,
				});

				if (resp) {
					this.success = true;
				}
			} catch (error) {
				this.formError = true;
				this.errorMessage = error;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.forgot-password {
	position: relative;
	display: flex;
	flex-flow: row wrap;

	&-form {
		display: inline-block;
		margin: 10px;
		width: 20rem;

		label {
			display: block;
			clear: both;
			margin: 10px;
			font-size: 12px;
		}

		input {
			display: block;
			clear: both;
			margin: 10px;
			border: 1px solid #a6c9e2;
			border-radius: 5px;
			background: #fcfdfd url("~@/assets/ui-bg_inset-hard_100_fcfdfd_1x100.png") 50% bottom repeat-x;
			color: #222;
			width: calc(100% - 20px);
		}

		&-btn {
			display: inline-block;
			margin: 10px;
			padding: 4px 11px;
			border: 1px solid #c5dbec;
			border-radius: 5px;
			background: #dfeffc url("~@/assets/ui-bg_glass_85_dfeffc_1x400.png") 50% 50% repeat-x;
			color: #2e6e9e;
			font-size: 1.1rem;

			&-link {
				color: #2e6e9e;
				font-size: 1.1rem;
				text-decoration: underline;
			}
		}

		&-images {
			margin-top: 210px;

			img {
				display: block;
				clear: both;
				margin: 20px;
			}
		}
	}

	&-infographic {
		background-color: #c8dbdf;
		padding: 10px;
		border: 1px solid #c0c0c0;
		border-radius: 5px;
		height: 514px;
		width: 272px;
		margin: 5px;
		line-height: initial;

		h3 {
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0.08em;
			margin: 0;
		}

		font {
			font-size: 1rem;
		}

		ul {
			li {
				font-size: 0.9rem;
			}
		}
	}
}

.modal-overlay {
	height: auto;
}
</style>
